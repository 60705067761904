import {useEffect, useState} from "react";
import styled from "styled-components";
import confetti from "canvas-confetti";
import * as anchor from "@project-serum/anchor";
import {LAMPORTS_PER_SOL, PublicKey} from "@solana/web3.js";
import {useAnchorWallet} from "@solana/wallet-adapter-react";
import {WalletMultiButton} from "@solana/wallet-adapter-react-ui";
import {GatewayProvider} from '@civic/solana-gateway-react';
import Countdown from "react-countdown";
import {Snackbar, Paper, LinearProgress, Chip} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {toDate, AlertState, getAtaForMint} from './utils';
import {MintButton} from './MintButton';
import {
    CandyMachine,
    awaitTransactionSignatureConfirmation,
    getCandyMachineState,
    mintOneToken,
    CANDY_MACHINE_PROGRAM,
} from "./candy-machine";


const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString() : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME ? process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME.toString() : "TOKEN";

const WalletContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const WalletAmount = styled.div`
  // color: black;
  // width: auto;
  // padding: 5px 5px 5px 16px;
  // padding: 0px 5px 0px 16px;
  // min-width: 48px;
  // min-height: auto;
  // border-radius: 22px;
  // background-color: var(--main-text-color);
  // box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  // box-sizing: border-box;
  // transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // font-weight: 900;
  // line-height: 18px;
  // font-size: 18px;
  // text-transform: uppercase;
  // border: 0;
  // margin: 0;
  // display: inline-flex;
  // outline: 0;
  // position: relative;
  // align-items: center;
  // user-select: none;
  // vertical-align: middle;
  // justify-content: flex-start;
  // gap: 10px;
  // font-family: futurespore;
  
`;

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`;

const ConnectButton = styled(WalletMultiButton)`

`;

const NFT = styled(Paper)`
  // min-width: 400px;
  // padding: 5px 20px 20px 20px;
  flex: 1 1 auto;
  background-color: var(--card-background-color) !important;

`;
const Des = styled(NFT)`
  text-align: left;
  padding-top: 0px;
`;

const Card = styled(Paper)`
  display: inline-block;
  background-color: var(--card-background-lighter-color) !important;
  margin: 5px;
  padding: 24px;
`;

const MintButtonContainer = styled.div`

  button.MuiButton-contained:not(.MuiButton-containedPrimary).Mui-disabled {
    color: #464646;
  }

  button.MuiButton-contained:not(.MuiButton-containedPrimary):hover,
  button.MuiButton-contained:not(.MuiButton-containedPrimary):focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
  }

  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }

  
`;

const Logo = styled.div`
  flex: 0 0 auto;

  img {
    height: 60px;
  }
`;
const Menu = styled.ul`
  list-style: none;
  display: inline-flex;
  flex: 1 0 auto;

  li {
    margin: 0 12px;

    a {
      color: var(--main-text-color);
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      outline: none;
      text-decoration: none;
      text-size-adjust: 100%;
      touch-action: manipulation;
      transition: color 0.3s;
      padding-bottom: 15px;

      img {
        max-height: 26px;
      }
    }

    a:hover, a:active {
      color: rgb(131, 146, 161);
      border-bottom: 4px solid var(--title-text-color);
    }

  }
`;

const SolExplorerLink = styled.a`
  color: var(--title-text-color);
  border-bottom: 1px solid var(--title-text-color);
  font-weight: bold;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;

  :hover {
    border-bottom: 2px solid var(--title-text-color);
  }
`;

const EmptyContainer  = styled.div`
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  // margin-right: 4%;
  // margin-left: 4%;
  text-align: center;
  justify-content: center;
`;

const MintContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: 20px;
`;

const DesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;
`;

const Price = styled(Chip)`
  position: absolute;
  margin: 5px;
  font-weight: bold;
  font-size: 1em !important;
`;

const Image = styled.img`
  height: 400px;
  width: auto;
  border-radius: 7px;
  box-shadow: 5px 5px 40px 5px rgba(0,0,0,0.5);
`;

const BorderLinearProgress = styled(LinearProgress)`
  margin: 20px 0;
  height: 30px !important;
  border-radius: 7px;
  border: 2px solid white;
  box-shadow: 5px 5px 40px 5px rgba(0,0,0,0.5);
  background-color:var(--main-text-color) !important;
  
  > div.MuiLinearProgress-barColorPrimary{
    background-color:var(--title-text-color) !important;
  }

  > div.MuiLinearProgress-bar1Determinate {
    border-radius: 7px !important;
    background-image:  linear-gradient(to right,#602d5f,#9ecad6) !important;
    background-color:  linear-gradient(to right,#602d5f,#9ecad6) !important;
  }
`;

const ShimmerTitle = styled.h1`
  margin: 50px auto;
  text-transform: uppercase;
  animation: glow 2s ease-in-out infinite alternate;
  color: var(--main-text-color);
  @keyframes glow {
    from {
      text-shadow: 0 0 20px var(--main-text-color);
    }
    to {
      text-shadow: 0 0 30px var(--title-text-color), 0 0 10px var(--title-text-color);
    }
  }
`;

const GoldTitle = styled.h2`
  color: var(--title-text-color);
`;

const LogoAligner = styled.div`
  display: flex;
  align-items: center;

  img {
    max-height: 35px;
    margin-right: 10px;
  }
`;

export interface HomeProps {
    candyMachineId: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    txTimeout: number;
    rpcHost: string;
}

const Home = (props: HomeProps) => {
    const [balance, setBalance] = useState<number>();
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
    const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
    const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
    const [itemsAvailable, setItemsAvailable] = useState(0);
    const [itemsRedeemed, setItemsRedeemed] = useState(0);
    const [itemsRemaining, setItemsRemaining] = useState(0);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const [payWithSplToken, setPayWithSplToken] = useState(false);
    const [price, setPrice] = useState(0);
    const [priceLabel, setPriceLabel] = useState<string>("SOL");
    const [whitelistPrice, setWhitelistPrice] = useState(0);
    const [whitelistEnabled, setWhitelistEnabled] = useState(false);
    const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);

    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const wallet = useAnchorWallet();
    const [candyMachine, setCandyMachine] = useState<CandyMachine>();

    const rpcUrl = props.rpcHost;

    const refreshCandyMachineState = () => {
        (async () => {
            if (!wallet) return;

            const cndy = await getCandyMachineState(
                wallet as anchor.Wallet,
                props.candyMachineId,
                props.connection
            );

            setCandyMachine(cndy);
            setItemsAvailable(cndy.state.itemsAvailable);
            setItemsRemaining(cndy.state.itemsRemaining);
            setItemsRedeemed(cndy.state.itemsRedeemed);

            var divider = 5;
            if (decimals) {
                divider = +('1' + new Array(decimals).join('0').slice() + '0');
            }

            // detect if using spl-token to mint
            if (cndy.state.tokenMint) {
                setPayWithSplToken(true);
                // Customize your SPL-TOKEN Label HERE
                // TODO: get spl-token metadata name
                setPriceLabel(splTokenName);
                setPrice(cndy.state.price.toNumber() / divider);
                setWhitelistPrice(cndy.state.price.toNumber() / divider);
            }else {
                setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
                setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
            }


            // fetch whitelist token balance
            if (cndy.state.whitelistMintSettings) {
                setWhitelistEnabled(true);
                if (cndy.state.whitelistMintSettings.discountPrice !== null && cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price) {
                    if (cndy.state.tokenMint) {
                        setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider);
                    } else {
                        setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / LAMPORTS_PER_SOL);
                    }
                }
                let balance = 0;
                try {
                    const tokenBalance =
                        await props.connection.getTokenAccountBalance(
                            (
                                await getAtaForMint(
                                    cndy.state.whitelistMintSettings.mint,
                                    wallet.publicKey,
                                )
                            )[0],
                        );

                    balance = tokenBalance?.value?.uiAmount || 0;
                } catch (e) {
                    console.error(e);
                    balance = 0;
                }
                setWhitelistTokenBalance(balance);
                setIsActive(balance > 0);
            } else {
                setWhitelistEnabled(false);
            }
        })();
    };

    const renderCounter = ({days, hours, minutes, seconds}: any) => {
        return (
            <div><Card elevation={1}><h1>{days}</h1><br/>Days</Card><Card elevation={1}><h1>{hours}</h1>
                <br/>Hours</Card><Card elevation={1}><h1>{minutes}</h1><br/>Mins</Card><Card elevation={1}>
                <h1>{seconds}</h1><br/>Secs</Card></div>
        );
    };

    function displaySuccess(mintPublicKey: any): void {
        let remaining = itemsRemaining - 1;
        setItemsRemaining(remaining);
        setIsSoldOut(remaining === 0);
        if (whitelistTokenBalance && whitelistTokenBalance > 0) {
            let balance = whitelistTokenBalance - 1;
            setWhitelistTokenBalance(balance);
            setIsActive(balance > 0);
        }
        setItemsRedeemed(itemsRedeemed + 1);
        const solFeesEstimation = 0.012; // approx
        if (!payWithSplToken && balance && balance > 0) {
            setBalance(balance - (whitelistEnabled ? whitelistPrice : price) - solFeesEstimation);
        }
        setSolanaExplorerLink(cluster === "devnet" || cluster === "testnet"
            ? ("https://explorer.solana.com/address/" + mintPublicKey + "?cluster=" + cluster)
            : ("https://explorer.solana.com/address/" + mintPublicKey));
        throwConfetti();
    };

    function throwConfetti(): void {
        confetti({
            particleCount: 600,
            spread: 120,
            origin: {y: 0.4},
        });
    }

    const onMint = async () => {
        try {
            setIsMinting(true);
            if (wallet && candyMachine?.program && wallet.publicKey) {
                const mint = anchor.web3.Keypair.generate();
                const mintTxId = (
                    await mintOneToken(candyMachine, wallet.publicKey, mint)
                )[0];

                let status: any = {err: true};
                if (mintTxId) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintTxId,
                        props.txTimeout,
                        props.connection,
                        'singleGossip',
                        true,
                    );
                }

                if (!status?.err) {
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });

                    // update front-end amounts
                    displaySuccess(mint.publicKey);
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                }
            }
        } catch (error: any) {
            // TODO: blech:
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x138')) {
                } else if (error.message.indexOf('0x137')) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
        } finally {
            setIsMinting(false);
        }
    };


    useEffect(() => {
        (async () => {
            if (wallet) {
                const balance = await props.connection.getBalance(wallet.publicKey);
                setBalance(balance / LAMPORTS_PER_SOL);
            }
        })();
    }, [wallet, props.connection]);

    useEffect(refreshCandyMachineState, [
        wallet,
        props.candyMachineId,
        props.connection,
    ]);

    return (
       











<EmptyContainer>
<div className="section" id="section0">
  <section className="text-center">
    <div>
      <main className="timeline_main">
        <div>
          <h1
            className="card-title headertitle"
            style={{ textAlign: "center" }}
            data-aos="fade-up"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="main-timeline main-timeline2 main-timeline-custom">
                <div className="timeline">
                  <div className="row">
                    <div className="col-md-6 align-self-center">
                      <div
                        className="timelineimgcontainerwrappers"
                        data-aos="fade-up"
                        data-aos-delay={250}
                      >
                        <div className="timelineimgcontainer gif">
                          <img
                            className="img-fluid leftimage"
                            src="imgs/ourpeople.gif"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 align-self-center ready-to-mint-column">


                    <div id="readytomintcolumnid">
                    <main>
            <MainContainer>

                <MintContainer>
                    <DesContainer>
                        <NFT elevation={3}>

                            {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) &&
                              <h2>You have {whitelistTokenBalance} whitelisted mint(s) remaining.</h2>}
                            {wallet && isActive &&
                                /* <p>Total Minted : {100 - (itemsRemaining * 100 / itemsAvailable)}%</p>}*/
                              // <div><h2>MINTED</h2> <h1>{ Math.round(itemsRedeemed + 120) }</h1><h3>/</h3><h1>{ 666 }</h1><h2>TOTALs</h2></div>}
                              <div><h2>MINTED</h2> <h1>{ Math.round(itemsRedeemed ) }</h1><h3>/</h3><h1>{ 256 }</h1><h2>TOTALs</h2></div>}
                            {wallet && isActive && <BorderLinearProgress variant="determinate"
                                                                        //  value={(((itemsRedeemed + 120) / 666) * 100)}/>}
                                                                         value={(((itemsRedeemed ) / 256) * 100)}/>}
                            <br/>
                            <MintButtonContainer>
                                {!isActive && candyMachine?.state.goLiveDate ? (
                                    <Countdown
                                        date={toDate(candyMachine?.state.goLiveDate)}
                                        onMount={({completed}) => completed && setIsActive(true)}
                                        onComplete={() => {
                                            setIsActive(true);
                                        }}
                                        renderer={renderCounter}
                                    />) : (
                                    !wallet ? (
                                      <ConnectButton>Connect Wallet</ConnectButton>
                                      //  <ConnectButton><img className="img-fluid" src='imgs\connect-wallet.png' /></ConnectButton>
                                        ) :
                                        candyMachine?.state.gatekeeper &&
                                        wallet.publicKey &&
                                        wallet.signTransaction ? (
                                            <GatewayProvider
                                                wallet={{
                                                    publicKey:
                                                        wallet.publicKey ||
                                                        new PublicKey(CANDY_MACHINE_PROGRAM),
                                                    //@ts-ignore
                                                    signTransaction: wallet.signTransaction,
                                                }}
                                                // // Replace with following when added
                                                // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                                                gatekeeperNetwork={
                                                    candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                                                } // This is the ignite (captcha) network
                                                /// Don't need this for mainnet
                                                clusterUrl={rpcUrl}
                                                options={{autoShowModal: false}}
                                            >
                                                <MintButton
                                                    candyMachine={candyMachine}
                                                    isMinting={isMinting}
                                                    isActive={isActive}
                                                    isSoldOut={isSoldOut}
                                                    onMint={onMint}
                                                />
                                            </GatewayProvider>
                                        ) : (
                                            <MintButton
                                                candyMachine={candyMachine}
                                                isMinting={isMinting}
                                                isActive={isActive}
                                                isSoldOut={isSoldOut}
                                                onMint={onMint}
                                            />
                                        ))}
                            </MintButtonContainer>
                            <br/>
                            <div><Price
                                label={isActive && whitelistEnabled && (whitelistTokenBalance > 0) ? (whitelistPrice + " " + priceLabel) : (price + " " + priceLabel)}/></div>
                              
                            {wallet && isActive && solanaExplorerLink &&
                              <SolExplorerLink href={solanaExplorerLink} target="_blank">View on Solana
                                Explorer</SolExplorerLink>}
                        </NFT>
                    </DesContainer>
              
                </MintContainer>
            </MainContainer>
            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({...alertState, open: false})}
            >
                <Alert
                    onClose={() => setAlertState({...alertState, open: false})}
                    severity={alertState.severity}
                >
                    {alertState.message}
                </Alert>
            </Snackbar>
        </main>

</div>


                      </div>
                    </div>
                  </div>
                </div>
                <div className="layer">
                  <div className="top-menu">
                    <div className="topnav">
                      <div className="topnav-centered">
                        <a href="#" className="active">
                          <img src="imgs/logo.png" height={110} />
                        </a>
                      </div>
                      <div className="mobilespace">&nbsp;</div>
                      <div className="topnav-left">
                        <a
                          href="https://discord.gg/twoside"
                          className="discord_link"
                        >
                          <img
                            src="imgs/discord-cr.png"
                            height={68}
                            className="discord_icon"
                          />
                        </a>
                        <a href="https://twitter.com/TwoSide_NFT">
                          <img src="imgs/twitter-cr.png" height={56} />
                        </a>
                        <a href="https://www.instagram.com/twosidenft/">
                          <img src="imgs/instagram-cr.png" height={56} />
                        </a>
                      </div>
                      <div className="topnav-right">
                      <Wallet>
                      {wallet ?
                            <WalletAmount id="walletamount">{(balance || 0).toLocaleString()} SOL<ConnectButton/></WalletAmount> :
                            <ConnectButton>Connect Wallet</ConnectButton>}
                        </Wallet>
                                        
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fullstorysection" id="our-story">
              <header className="storysection min-vh-100">
                <a id="our-story" />
                <div className="container-fluid">
                  <div className="align-items-center flex-md-row-reverse row container-fluid">
                    <div className="min-vh-100">
                      <h1
                        className="mb-6 text-light-1 text-center"
                        data-aos="fade-down"
                        data-aos-delay={150}
                        style={{ marginTop: 100 }}
                      >
                        ARTISTICALLY NFT
                      </h1>
                      <p
                        className="mb-8 text-light-2 storytext col-8 offset-2"
                        data-aos="fade-down"
                        data-aos-delay={250}
                        style={{ margin: "100px auto" }}
                      >
                        We have a completely new take on NFTs: An artisan
                        one. Reinventing PFPs from every “side” by combining
                        views from every all worlds: A digital artist with a
                        classically trained one. A hot-shot Web 3.0 Dapp
                        programmer with a computer scientist. An Influencer
                        with a Publicist. (an old-school Mad Men) A digital
                        coin investor with a stock trader. A startup bro
                        with a corporate agent. And our art shine something
                        from each and every one of US; because WE all take a
                        side and yet WE all have Two Sides. And WE are
                        building a community to reflect that: People from
                        all parts of life, always remindful of and looking
                        forward to connect with the other side; whether it’s
                        their other side, or someone else’s.
                      </p>
                      <p
                        className="mb-8 text-light-2 storytext col-8 offset-2"
                        data-aos="fade-down"
                        data-aos-delay={500}
                        style={{ margin: "75px auto" }}
                      >
                        Instead of just making up a few layers and auto
                        generating, not only our artists painstakingly
                        designed each “side” and their stories, but also we
                        as a whole team have had to sign off each and every
                        one of them: Authentically matchless. AND, on top of
                        all that, our artists also hand crafted utterly
                        unique ones; “real” one-of-ones.
                      </p>
                    </div>
                  </div>
                </div>
              </header>
            </div>
            <a id="characters" />
            <main>
              <div className="container text-center py-20">
                <div className="row mx-auto my-auto">
                  <div className="slider">
                    <div className="slider__wrapper">
                      <div className="slider__item col-md-12 active">
                        <div>
                          <img
                            className="img-fluid"
                            src="imgs/man-zombie-cr.jpg"
                          />
                        </div>
                        <div className="slider__item_link" />
                      </div>
                      <div className="slider__item col-md-12">
                        <div>
                          <img
                            className="img-fluid"
                            src="imgs/woman-mermaid-cr.jpg"
                          />
                        </div>
                        <div className="slider__item_link" />
                      </div>
                      <div className="slider__item col-md-12">
                        <div>
                          <img
                            className="img-fluid"
                            src="imgs/zombie-man-cr.jpg"
                          />
                        </div>
                        <div className="slider__item_link" />
                      </div>
                      <div className="slider__item col-md-12">
                        <div>
                          <img
                            className="img-fluid"
                            src="imgs/pin-woman-cr.jpg"
                          />
                        </div>
                        <div className="slider__item_link" />
                      </div>
                      <div className="slider__item col-md-12">
                        <div>
                          <img
                            className="img-fluid"
                            src="imgs/galaxy-woman-cr.jpg"
                          />
                        </div>
                        <div className="slider__item_link" />
                      </div>
                    </div>
                    <div className="carousel-indicators" data-aos="zoom-in">
                      <button
                        className="indicator-button ab"
                        id="carousel-selector-0"
                      />
                      <button
                        className="indicator-button ab"
                        id="carousel-selector-1"
                      />
                      <button
                        className="indicator-button ab"
                        id="carousel-selector-2"
                      />
                      <button
                        className="indicator-button ab"
                        id="carousel-selector-3"
                      />
                      <button
                        className="indicator-button ab"
                        id="carousel-selector-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <a id="roadmap" />
            <section className="text-center roadmap_section">
              <div id="completetimeline">
                <main className="timeline_main">
                  <div className="container-fluid timelinecontainer">
                    <h1
                      className="card-title roadmaptitle"
                      style={{ textAlign: "center" }}
                      data-aos="fade-up"
                    >
                      ROADMAP
                    </h1>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="main-timeline">
                          <div className="timeline">
                            <div className="row">
                              <div className="col-md-6 align-self-center">
                                <div
                                  className="timelineimgcontainer"
                                  data-aos="fade-up"
                                  data-aos-delay={250}
                                >
                                  <img
                                    className="img-fluid leftimage flyup"
                                    src="imgs/roadmap1-tr.png"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 align-self-center">
                                <div
                                  className="timeline-content"
                                  data-aos="fade-up"
                                  data-aos-delay={500}
                                >
                                  <div>
                                    <h3 className="title">Q1 - GENESIS</h3>
                                  </div>
                                  <p className="description">
                                    Discord and Website Launch
                                  </p>
                                  <p className="description">
                                    Partnership Announcements
                                  </p>
                                  <p className="description">
                                    Minting Phase
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timeline ct">
                            <div className="row">
                              <div className="col-md-6 align-self-center ordermobile2">
                                <div
                                  className="timeline-content"
                                  data-aos="fade-up"
                                  data-aos-delay={250}
                                >
                                  <div>
                                    <h3 className="title righttitle">
                                      Q2 - COMMUNITY
                                    </h3>
                                  </div>
                                  <p className="description">
                                    Launching Two Side Community Hub,
                                    holding Two Side NFT will grant a
                                    membership
                                  </p>
                                  <p className="description">
                                    Buyback 20% Two Side Nfts from our
                                    community and displaying them on our
                                    Metaverse Gallery
                                  </p>
                                  <p className="description">
                                    Our holders can display (stake) their
                                    Two Side Nfts on our gallery and gain
                                    passive income from the visitors fees,
                                    trades and royalty fees
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6 ordermobile1 align-self-center">
                                <div
                                  className="timelineimgcontainer"
                                  data-aos="fade-up"
                                  data-aos-delay={500}
                                >
                                  <img
                                    className="img-fluid rightimage rotate_later"
                                    src="imgs/roadmap2-tr.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timeline">
                            <div className="row">
                              <div className="col-md-6 align-self-center">
                                <div
                                  className="timelineimgcontainer"
                                  data-aos="fade-up"
                                  data-aos-delay={250}
                                >
                                  <svg
                                    viewBox="0 0 50 50"
                                    width={50}
                                    height={50}
                                    className="roadmap3"
                                  >
                                    <defs>
                                      <mask
                                        id="mask"
                                        x={0}
                                        y={0}
                                        width={100}
                                        height={49}
                                      >
                                        <rect
                                          x={0}
                                          y={0}
                                          width={50}
                                          height={50}
                                          fill="#fff"
                                        />
                                        <text
                                          className="svg_text svg_text1"
                                          x={25}
                                          id="ltsrV"
                                          textAnchor="middle"
                                          y={50}
                                          dy="-3.5"
                                          style={{
                                            fontFamily: "futurespore",
                                            opacity: 1,
                                            zIndex: 1,
                                          }}
                                        >
                                          T
                                        </text>
                                        <text
                                          className="svg_text svg_text2"
                                          x={25}
                                          id="ltrV"
                                          textAnchor="middle"
                                          y={50}
                                          dy={0}
                                          style={{
                                            fontFamily: "futurespore",
                                            opacity: 1,
                                            zIndex: 5,
                                          }}
                                        >
                                          S
                                        </text>
                                      </mask>
                                    </defs>
                                    <rect
                                      x={0}
                                      y={0}
                                      width={50}
                                      height={50}
                                      mask="url(#mask)"
                                      fillOpacity="0.5"
                                      fill="#E3DFD2"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <div className="col-md-6 align-self-center">
                                <div
                                  className="timeline-content"
                                  data-aos="fade-up"
                                  data-aos-delay={500}
                                >
                                  <h3 className="title">Q3 - LAUNCH</h3>
                                  <p className="description">
                                    Our Members can create, display and sell
                                    their own Nft collections at our
                                    Metaverse gallery
                                  </p>
                                  <p className="description">
                                    Airdrops to our Nft holders for a chance
                                    to display more art in the gallery
                                  </p>
                                  <p className="description">
                                    Buying more land in Metaverse and
                                    expanding
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="timeline">
                            <div className="row">
                              <div className="col-md-6 align-self-center ordermobile2">
                                <div
                                  className="timeline-content"
                                  data-aos="fade-up"
                                  data-aos-delay={250}
                                >
                                  <h3 className="title righttitle">
                                    Q4 - FUTURE
                                  </h3>
                                  <p className="description">
                                    More merch and utilities to our Members
                                  </p>
                                  <p className="description">
                                    Exclusive Metaverse events
                                  </p>
                                  <p className="description">
                                    3D Two Sides are coming
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6 ordermobile1 align-self-center">
                                <div
                                  className="timelineimgcontainer"
                                  data-aos="fade-up"
                                  data-aos-delay={500}
                                >
                                  <img
                                    className="img-fluid rightimage rotate_later"
                                    src="imgs/roadmap4.png"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </section>
          </div>
      </main>
    </div>
  </section>
  <a id="partners" />
  <section className="py-85 text-center">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6 mb-12">
          <h1
            className="mb-12 text-light-1"
            data-aos="fade-down"
            data-aos-delay={0}
          >
            PARTNERS
          </h1>
        </div>
      </div>
      <div className="row justify-content-center mb-lg-12">
        <div
          className="col-lg-4 col-md-4 col-sm-12"
          data-aos="fade-right"
          data-aos-delay={250}
        >
          <img
            src="imgs/magiceden-uc-cr.png"
            alt="magiceden.io"
            className="img-fluid mb-6 partnerimg"
          />
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12 partnercolmiddle"
          data-aos="fade-down"
          data-aos-delay={400}
        >
          <img
            src="imgs/thesandbox-uc-cr.png"
            alt="sandbox.game"
            className="img-fluid mb-6 partnerimg"
          />
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12"
          data-aos="fade-left"
          data-aos-delay={500}
        >
          <img
            src="imgs/howrareis-uc-cr.png"
            alt="howrare.is"
            className="img-fluid mb-6 partnerimg"
          />
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12"
          data-aos="fade-left"
          data-aos-delay={1000}
        >
          <img
            src="imgs/solanart-3-cr.png"
            alt="solanart.com"
            className="img-fluid mb-6 partnerimg"
          />
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12"
          data-aos="fade-up"
          data-aos-delay={750}
        >
          <img
            src="imgs/crypto-dot-com-cr.png"
            alt="crypto.com"
            className="img-fluid mb-6 partnerimg"
          />
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12"
          data-aos="fade-right"
          data-aos-delay={1250}
        >
          <img
            src="imgs/upcoming-square-uc.png"
            alt="upcomingnft.net"
            className="img-fluid mb-6 partnerimg"
          />
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-12"
          data-aos="fade-up"
          data-aos-delay={1600}
        >
          <img
            src="imgs/digital-eyes-cr.png"
            alt="digitaleyes.market"
            className="img-fluid mb-6 partnerimg"
          />
        </div>
      </div>
    </div>
  </section>
  <a id="faq" />
  <section id="faq" className="py-10">
    <div className="container">
      <div className="p-2 rounded">
        <div className="row">
          <div className="col-xl-4 col-lg-5 faq-title-column">
            <div
              className="faq-title"
              data-aos="fade-down"
              data-aos-delay={150}
            >
              <div>
                <h1>Frequently asked Questions</h1>
              </div>
              <div className="my-10 faq-title-subtext">
                Dive into our FAQ for more details:
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-7">
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={250}
            >
              <summary>
                <div className="summarypointer" />
                What blockchain will Two Side NFTs launch on?
              </summary>
              <p>
                Two Side NFTs will be launching on the Solana blockchain.
              </p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={350}
            >
              <summary>
                <div className="summarypointer" />
                When will be the launch date?
              </summary>
              <p>
                Two Side NFTs can be minted on <b>February 24th</b>.
              </p>
              <p>Stay up to date with our Discord server.</p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={450}
            >
              <summary>
                <div className="summarypointer" />
                How much do Two Side NFTs cost?
              </summary>
              <p>
                The pricing will be auto-determined based on market conditions and demand.
              </p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={550}
            >
              <summary>
                <div className="summarypointer" />
                How many Two Side NFTs are there?
              </summary>
              <p>There will be a total supply of 256 Two Side NFTs.</p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={650}
            >
              <summary>
                <div className="summarypointer" />
                Will there be a presale?
              </summary>
              <p>
                Yes. We will be holding a presale half an hour prior to the
                public launch. Our presale will only be available to our
                Whitelist and OG members. To learn more on how you can join
                our Whitelist, please join our discord and check
                📃how-to-whitelist .
              </p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={750}
            >
              <summary>
                <div className="summarypointer" />
                How can the people mint for the presale?
              </summary>
              <p>
                A channel will be open for WL and OG members before the
                minting date. We will get your Solana wallet adresses and
                those wallet addresses will be able to mint for presale.
              </p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={850}
            >
              <summary>
                <div className="summarypointer" />
                Are there secondary sale royalties?
              </summary>
              <p>Yes, there will be a small secondary sale royalty.</p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={950}
            >
              <summary>
                <div className="summarypointer" />
                Where can I view my Two Side NFTs?
              </summary>
              <p>
                After public mint, Two Side NFTs will be revealed on SolSea
                and MagicEden. Once revealed, you will be able to see it by
                connecting your crypto wallet to according marketplaces.
              </p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={1050}
            >
              <summary>
                <div className="summarypointer" />
                How are you going to provide value to your owners?
              </summary>
              <p>
                We have a ton of utility planned. Our main goal with this
                project is to give back to both communities we love. As we
                progress on our roadmap and with the project you will be
                very excited about what we are bringing. With the staking
                option, our holders will have a passive income.
              </p>
            </details>
            <details
              className="faq-item"
              data-aos="fade-down"
              data-aos-delay={1150}
            >
              <summary>
                <div className="summarypointer" />
                Will there be merch and utility?
              </summary>
              <p>
                The two coincide with one another so we can speak much about
                it. However, We can say we are talking with distributors and
                are extremely excited to share it with everyone when the
                time comes.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  </section>
  <a id="social" />
  <footer className="pt-8 pb-12 bg-bg-1">
    <div className="container">
      <div className="row">
        <div
          className="col-md-4 col-xl-2 ordermobile2 align-self-end"
          data-aos="fade-down"
          data-aos-delay={0}
        >
          <div className="d-flex justify-content-center">
            <a href="#">
              <img
                src="imgs/logo.png"
                alt="Two-Side Logo"
                className="img-fluid footerlogo"
              />
            </a>
          </div>
        </div>
        <div
          className="col-md-4 col-xl-2 offset-xl-6 middlecolumn ordermobile1 align-self-start"
          data-aos="fade-down"
          data-aos-delay={400}
        >
          <div className="row d-flex justify-content-center">
            <div>
              <h5 className="text-light-1 followcontacttexts">FOLLOW US</h5>
            </div>
            <div className="row">
              <div className="footerlogos">
                <div className="d-flex">
                  <a href="https://discord.gg/twoside">
                    <img
                      src="imgs/discord-cr.png"
                      alt="Discord"
                      className="img-fluid followuslinks discord"
                    />
                  </a>
                </div>
              </div>
              <div className="footerlogos">
                <div className="d-flex">
                  <a href="https://twitter.com/TwoSide_NFT">
                    <img
                      src="imgs/twitter-cr.png"
                      alt="Twitter"
                      className="img-fluid footertwitter followuslinks"
                    />
                  </a>
                </div>
              </div>
              <div className="footerlogos">
                <div className="d-flex">
                  <a href="https://www.instagram.com/twosidenft/">
                    <img
                      src="imgs/instagram-cr.png"
                      alt="Instagram"
                      className="img-fluid followuslinks"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-4 col-xl-2 ordermobile1 rightcolumn align-self-start"
          data-aos="fade-down"
          data-aos-delay={500}
        >
          <h5 className="text-light-1 followcontacttexts">CONTACT US</h5>
          <a
            href="mailto:info@two-side.io"
            className="mb-4 me-2 me-sm-0 d-block text-action-1 fw-bold text-light-1 mailtotext"
          >
            info@two-side.io
          </a>
        </div>
      </div>
      <p className="fs-5 mb-0 mt-lg-0 mt-6 text-light-2 invisible">
        Copyright © 2022 Two Side. All Rights Reserved.
      </p>
    </div>
  </footer>
</div>
</EmptyContainer>






    );
};

export default Home;
